@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 640px) {
  .max-payment-info {
    position: relative;
  }
  
  .max-payment-info::before {
    content: "MAX";
    position: absolute;
    left: -40px;
  }
}
